import { default as _91slug_93cMDRQmLZ9RMeta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/[slug].vue?macro=true";
import { default as index0XzUlHVIRTMeta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/index.vue?macro=true";
import { default as _91page_933U0l6YuY37Meta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/page/[page].vue?macro=true";
import { default as _404ORU2ikale2Meta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/404.vue?macro=true";
import { default as indexTfcNzva1x0Meta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/index.vue?macro=true";
import { default as our_45familyY1GkLc7AEpMeta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/our-family.vue?macro=true";
import { default as subscribec6wMg2JxpAMeta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/subscribe.vue?macro=true";
import { default as work_45with_45usFcJ3OGcKAkMeta } from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/work-with-us.vue?macro=true";
export default [
  {
    name: _91slug_93cMDRQmLZ9RMeta?.name ?? "category-slug",
    path: _91slug_93cMDRQmLZ9RMeta?.path ?? "/:category()/:slug()",
    meta: _91slug_93cMDRQmLZ9RMeta || {},
    alias: _91slug_93cMDRQmLZ9RMeta?.alias || [],
    redirect: _91slug_93cMDRQmLZ9RMeta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: index0XzUlHVIRTMeta?.name ?? "category",
    path: index0XzUlHVIRTMeta?.path ?? "/:category()",
    meta: index0XzUlHVIRTMeta || {},
    alias: index0XzUlHVIRTMeta?.alias || [],
    redirect: index0XzUlHVIRTMeta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_933U0l6YuY37Meta?.name ?? "category-page-page",
    path: _91page_933U0l6YuY37Meta?.path ?? "/:category()/page/:page()",
    meta: _91page_933U0l6YuY37Meta || {},
    alias: _91page_933U0l6YuY37Meta?.alias || [],
    redirect: _91page_933U0l6YuY37Meta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/[category]/page/[page].vue").then(m => m.default || m)
  },
  {
    name: _404ORU2ikale2Meta?.name ?? "404",
    path: _404ORU2ikale2Meta?.path ?? "/404",
    meta: _404ORU2ikale2Meta || {},
    alias: _404ORU2ikale2Meta?.alias || [],
    redirect: _404ORU2ikale2Meta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: indexTfcNzva1x0Meta?.name ?? "index",
    path: indexTfcNzva1x0Meta?.path ?? "/",
    meta: indexTfcNzva1x0Meta || {},
    alias: indexTfcNzva1x0Meta?.alias || [],
    redirect: indexTfcNzva1x0Meta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: our_45familyY1GkLc7AEpMeta?.name ?? "our-family",
    path: our_45familyY1GkLc7AEpMeta?.path ?? "/our-family",
    meta: our_45familyY1GkLc7AEpMeta || {},
    alias: our_45familyY1GkLc7AEpMeta?.alias || [],
    redirect: our_45familyY1GkLc7AEpMeta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/our-family.vue").then(m => m.default || m)
  },
  {
    name: subscribec6wMg2JxpAMeta?.name ?? "subscribe",
    path: subscribec6wMg2JxpAMeta?.path ?? "/subscribe",
    meta: subscribec6wMg2JxpAMeta || {},
    alias: subscribec6wMg2JxpAMeta?.alias || [],
    redirect: subscribec6wMg2JxpAMeta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: work_45with_45usFcJ3OGcKAkMeta?.name ?? "work-with-us",
    path: work_45with_45usFcJ3OGcKAkMeta?.path ?? "/work-with-us",
    meta: work_45with_45usFcJ3OGcKAkMeta || {},
    alias: work_45with_45usFcJ3OGcKAkMeta?.alias || [],
    redirect: work_45with_45usFcJ3OGcKAkMeta?.redirect || undefined,
    component: () => import("/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/pages/work-with-us.vue").then(m => m.default || m)
  }
]