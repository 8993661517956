import revive_payload_client_4sVQNw7RlN from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/phill/Code/personal/leeleeloves/leeleeloves-nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  chunk_reload_client_UciE0i6zes
]